import { useState } from "react";
import CreateBlog from "../Components/Blog/CreateBlog";
import ViewBlog from "../Components/Blog/ViewBlog";
import Layout from "../Components/Layout";
import { useAddBlogCategoryMutation, useGetBlogCategoryQuery, useDeleteBlogCatMutation } from "../features/api/apiSlice";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button
} from '@chakra-ui/react'

const Ads = () => {
  const [page, setPage] = useState(0);
  const [addBlog] = useAddBlogCategoryMutation()
  const { data, isLoading } = useGetBlogCategoryQuery()
  const [error, setError] = useState()
  const [deleteCat] = useDeleteBlogCatMutation();
  const [msg, setMsg] = useState()

  const OverlayTwo = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='80%'
      backdropBlur='2px'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayTwo />)
  const [title, setTitle] = useState()
  
  const handlesubmit = async ()=>{
    if(!title){
      return setError("Category title is required")
    }
    setError("")
    const payload = {
      title
    }

    const response = await addBlog(payload)
    console.log(response)
    if(response.data.msg){
      setMsg(response.data.msg)
    }
    setTimeout(() => {
      onClose()
    }, 2000);

  }

  const handleDeleteCat = async(id)=>{
    if (!window.confirm("Are you sure?")) {
      return;
    }

    console.log("this is id", id)

    try {
      const data = await deleteCat({ _id: id });
      console.log(data);
    } catch (error) {
      console.log(error);
    }

  }


  const BlogCategory = ()=>{
    return(
      <>
      <h1 className="py-5">Blog Categories</h1>
      {isLoading  ? <div>Loading ...</div> : null}

     

    <table className="table">
    <thead>
      
        <th>Blog Category title</th>
            <th>Action</th>
      
    </thead>
    <tbody>
    
      {
        data?.map((i, index)=>{
          return(
            <tr key={index}>
              <td>{i.title}</td>
              <td>
              <button
          className="bg-orange text-white font-subtext py-[2px] px-[10px] font-[500] rounded-sm"
          onClick={() => handleDeleteCat(i?._id)}
        >
          Delete
        </button>
              </td>
              </tr>
          )
        })
      }
     
    </tbody>  
  </table>
          
        
      
     </>
    )}

      
      
     
      

    
    
  


  return (
    <Layout
      child={
        <div className="flex items-center justify-center">
          <div className="min-w-[60%]">
            <div className="w-full h-[2rem] bg-orange px-[15px] flex items-center text-white text-[0.8rem]">
              <div
                className="h-[10px] pr-[15px] border-r-2 text-white flex items-center cursor-pointer"
                onClick={() => setPage(0)}
              >
                Create Blog
              </div>
              <div
                className="h-[10px] px-[15px] text-white flex items-center cursor-pointer"
                onClick={() => setPage(1)}
              >
                View Blog
              </div>

              <div
                className="h-[10px] px-[15px] text-white flex items-center cursor-pointer"
                onClick={() => setPage(2)}
              >
                Blog Category
              </div>

              <div
                className="h-[10px] px-[15px] text-white flex items-center cursor-pointer"
                onClick={() => {
                  setOverlay(<OverlayTwo />)
                  onOpen()
                }}
              >
                Create blog category
              </div>

            </div>
             <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader className="text-orange">Create a new blog category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="text-red mt-5, mb-5">{error}</div>
            <div className="text-green mt-5, mb-5">{msg}</div>
            
          <input
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Blog title"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[5px] outline-0 mb-5 text-[14px] p-[10px] text-[#717579] placeholder-[#717579]"
        />
          </ModalBody>
          <ModalFooter>
            <Button className="bg-orange" onClick={handlesubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
            {page === 0 ? <CreateBlog /> :  page === 1 ? <ViewBlog /> : <BlogCategory />}
          </div>
        </div>
      }
    />
  );
};

export default Ads;
