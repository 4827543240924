import Moment from "react-moment";
import {  useGetBlogQuery, useDeleteBlogMutation} from "../../features/api/apiSlice";

function ViewBlog() {
  const { data: blog, isFetching } =  useGetBlogQuery();
  const [deleteBlog] = useDeleteBlogMutation();

console.log("blog data", blog)

const handleDeleteBlog = async(id)=>{
  console.log("this is ID", id)
  if (!window.confirm("Are you sure?")) {
    return;
  }

  try {
    const data = await deleteBlog({ _id: id });
    console.log(data);
  } catch (error) {
    console.log(error);
  }

}
  


  const Blog = blog?.map((blog, index) => (
    <tr
      key={index}
      className="mb-[20px] border-t-[1px] border-t-barGrey py-[5px]"
    >
      <td className="max-w-[5rem] text-[14px]">{blog?.title}</td>
      <td className="w-[2rem]">
        <img
          src={blog?.image}
          alt=""
          className="h-[30px] w-[30px] m-[0 auto]"
        />
      </td>
      <td className="max-w-[5rem] text-[14px]">{blog?.category}</td>
     
      <td className="max-w-[5rem] text-[14px]">{blog?.postedby}</td>
      <td className="max-w-[5rem] text-[14px]">
        <Moment format="MMMM DD, YYYY">{blog?.updatedAt}</Moment>
      </td>
      <td>
      <button
          className="bg-orange text-white font-subtext py-[2px] px-[10px] font-[500] rounded-sm"
          onClick={() => handleDeleteBlog(blog?._id)}
        >
          Delete
        </button>
      </td>
     
     
    </tr>
  ));

  return (
    <div className="w-full h-[80vh] bg-white rounded-[5px]  mt-[20px] drop-shadow p-[20px] overflow-y-auto">
      <div className="flex items-center rounded-[20px] border-deepGrey border-[1px] pl-[15px]">
        <img
          src="/assets/images/search.png"
          alt=""
          className="h-[20px] w-[20px]"
        />
        <input
          type="text"
          placeholder="Search brand, model or category "
          className="w-[90%] border-0 h-[30px] rounded-[2px] outline-0  text-[12px] p-[5px] text-[#717579] "
        />
      </div>
      <table className=" w-full mt-10 overflow-y-auto">
        <thead className="font-text text-[12px] text-left">
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Category</th>
        
            <th>Posted by</th>
            <th>Created Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="font-text text-[12px] text-left">
          {isFetching ? "Loading..." : Blog}
        </tbody>
      </table>
    </div>
  );
}

export default ViewBlog;
