import { useState } from "react";
import CreateAds from "../Components/Ads/CreateAds";
import ViewAds from "../Components/Ads/ViewAds";
import Layout from "../Components/Layout";

const Ads = () => {
  const [page, setPage] = useState(0);

  return (
    <Layout
      child={
        <div className="flex items-center justify-center">
          <div className="min-w-[700px]">
            <div className="w-full h-[2rem] bg-orange px-[15px] flex items-center text-white text-[0.8rem]">
              <div
                className="h-[10px] pr-[15px] border-r-2 text-white flex items-center cursor-pointer"
                onClick={() => setPage(0)}
              >
                Create Ads
              </div>
              <div
                className="h-[10px] px-[15px] text-white flex items-center cursor-pointer"
                onClick={() => setPage(1)}
              >
                View Ads
              </div>
            </div>
            {page === 0 ? <CreateAds /> : <ViewAds />}
          </div>
        </div>
      }
    />
  );
};

export default Ads;
