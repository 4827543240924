import { useEffect, useState } from "react";
import {
  useCreateAdMutation,
  useGetCategoryQuery,
  useGetSubCategoryQuery,
  useAddProductMutation
} from "../../features/api/apiSlice";

const CreateAds = () => {
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [subCategorySelected, setSubCategorySelected] = useState(null);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [duration, setDuration] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cloudUrl, setCloudUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('')

  const [addProduct] = useAddProductMutation();
  const { data: ProductCategory } = useGetCategoryQuery();
  const { data: ProductSubCategory } = useGetSubCategoryQuery();

  // upload image to cloudinary
  const Upload = async (e) => {
    if(!title){
      return  alert('Please enter a title for the Ad')
    }

    if(!cloudUrl){
      return alert('Please select an Image to upload')
    }

    if(!categorySelected){
      return alert('Please a  Category for your Ad')
    }
    if(!amount){
      return alert('Please enter the amount that was paid for this Ad')
    }
    if(!location){
      return alert('Select where you want this ad to display on mobile app')
    }
    console.log('uploading image')
    e.preventDefault();
    const data = new FormData();
    data.append("file", imageUrl);
    data.append("upload_preset", "mybuddy");
    data.append("cloud_name", "uhembe");
    setLoading(true);
    await fetch("https://api.cloudinary.com/v1_1/uhembe/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then( async (data) => {
        //upload product thumbnail
        const url = await data.secure_url;
        console.log("url", url)
        setCloudUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (cloudUrl !== null) {
      handleCreateAd();
      setLoading(false);
      setCloudUrl(null);
      setImageUrl("");
      // setTitle("");
      // setAmount("");
      // setDuration("");
    }
  }, [cloudUrl]);

  const handleCreateAd = async () => {
    const product = {
      productName:title,
      amount,
      status: "active", 
       productImage: cloudUrl,
      duration,
      category: categorySelected,
      subCategory: subCategorySelected,
      expiryDate,
      adonly: "yes",
      isAd: "yes",
      location
    };

    console.log("product payload", product)

   

    try {



     const response =  await addProduct(product).unwrap();
     console.log("this is ad", response)
     if(response.msg == "Product saved successfully"){
      alert("Add created successfully")
      setCloudUrl("");
      setImageUrl("");
      setTitle("");
      setAmount("");
      setDuration("");
      setLocation("")
      
  
     }else{
      alert('there was an error creating this Ad')
     }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full bg-white rounded-[5px] h-fit mt-[20px] drop-shadow p-[20px]">
      <form
        className="flex flex-col"
        onSubmit={Upload}
        encType="multipart/form-data"
      >
        <input
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Ad title"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        />
        <input
          onChange={(e) => setAmount(e.target.value)}
          type="text"
          placeholder="Amount paid"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-black text-[14px] p-[8px] placeholder-[#717579]"
        />

        <div className="mb-[20px] relative">
          <div
            className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 text-[14px] p-[8px] bg-white text-[#717579] flex justify-between items-center "
            onClick={() => setOpenCategory(!openCategory)}
          >
            <span>
              {categorySelected !== null ? categorySelected : "Ad category"}
            </span>{" "}
            <img
              src="/assets/images/arrowDown.png"
              alt=""
              className={`h-[5px] w-[8px] ${openCategory && "rotate-180"}`}
            />
          </div>
          <ul
            className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
              !openCategory && "hidden "
            }`}
          >
            {ProductCategory?.map((category) => (
              <li
                className={`w-full border-y-[1px] border-y-white p-[8px]  hover:bg-orange hover:text-white text-[14px]  ${
                  categorySelected === category?.title
                    ? "bg-orange text-white"
                    : "bg-white text-[#717579]"
                }`}
                onClick={() => {
                  setCategorySelected(category?.title);
                  setSubCategorySelected(null);
                  setOpenCategory(false);
                }}
              >
                {category?.title}
              </li>
            ))}
          </ul>
        </div>

        {/* <div className="mb-[20px] relative">
          <div
            className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 text-[14px] p-[8px] bg-white text-[#717579] flex justify-between items-center "
            onClick={() => setOpenSubCategory(!openSubCategory)}
          >
            <span>
              {subCategorySelected !== null
                ? subCategorySelected
                : "Ad sub category"}
            </span>{" "}
            <img
              src="/assets/images/arrowDown.png"
              alt=""
              className={`h-[5px] w-[8px] ${openSubCategory && "rotate-180"}`}
            />
          </div>
          <ul
            className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
              !openSubCategory && "hidden "
            }`}
          >
            {ProductSubCategory?.data?.map((subcategory) => (
              <li
                className={`w-full border-y-[1px] border-y-white p-[8px]  hover:bg-orange hover:text-white text-[14px]  ${
                  subCategorySelected === subcategory?.category
                    ? "bg-orange text-white"
                    : "bg-white text-[#717579]"
                }`}
                onClick={() => {
                  setSubCategorySelected(subcategory?.category);
                  setOpenSubCategory(false);
                }}
              >
                {subcategory?.category}
              </li>
            ))}
          </ul>
        </div> */}

        <label
          htmlFor="imageUrl"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] bg-white text-[#717579]"
        >
          {imageUrl?.name ? imageUrl?.name : "Ad image"}
        </label>
        <input
          onChange={(e) => setImageUrl(e.target.files[0])}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          name="imageUrl"
          id="imageUrl"
          className="hidden"
        />
        <input
          onChange={(e) => setDuration(e.target.value)}
          type="text"
          placeholder="Duration"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[#717579] text-[14px] p-[8px] placeholder-[#717579]"
        />
        <label htmlFor="expiryDate" className="text-[#717579] text-[10px]">
          Expiry Date
        </label>
        <input
          onChange={(e) => setExpiryDate(e.target.value)}
          type="date"
          name="expiryDate"
          id="expiryDate"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[#717579] text-[14px] p-[8px] placeholder-[#717579]"
        />

<label  className="w-full border-[#D9D9D9] text-[14px] p-[5px] bg-white text-[#717579]">Select where you want this ad to display on mobile app</label>
        <select  
        value={location} 
        onChange={e => setLocation(e.target.value)} 
        className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-3 text-[14px] p-[8px] bg-white text-[#717579]">
          <option value="all">All Screens</option>
          <option value="blog">Blog Screen</option>
          <option value="music">Music Screen</option>
          <option value="movie">Movie Screen</option>
          <option value="travel">Travel Screens</option>
          <option value="topup">Topup Screen</option>
          <option value="cable">Cable Screens</option>
          <option value="electricity">Electricity Screen</option>
          <option value="magazine">Magazine Screens</option>
          <option value="sport">Sport Screen</option>
        </select>



        <button
          className="w-full h-[30px] rounded-[2px] text-[14px] p-[8px] bg-orange text-white mt-[5px]"
          disabled={loading}
        >
          {loading ? "Uploading..." : "Create Ad"}
        </button>
      </form>
    </div>
  );
};

export default CreateAds;
