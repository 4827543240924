import React from "react";
import MainNavigation from "../Components/MainNavigation";

const Landing = () => {
  return (
    <div className="bg-black min-h-screen w-full pb-[2rem]">
      <MainNavigation />
      <div className="flex justify-between flex-col md:flex-row w-[100%]  ">
        <div className="w-[85%] md:w-[70%]  mt-[30px]">
          <div className="text-white text-[40px] lg:text-[80px]  font-text font-bold ml-10 w-full p-10 leading-tight">
            Adboard, where modern advertising meets creativity and innovation.
          </div>
          <div className="text-white lg:text-[25px] font-subtext ml-20 lg:max-w-[40rem]">
            Our expert team utilizes the latest tools and technologies to help
            you connect with your audience like never before.
          </div>
          <button className="bg-orange text-white font-text text-sm ml-20 mt-10 p-2 h-[50px] px-6 rounded">
            Download our app
          </button>
        </div>
        <div className=" md:w-[30%] mt-[5rem]">
          <img src="/assets/images/astronaut.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
