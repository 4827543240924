import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../features/api/apiSlice";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../features/user/userSlice";

const Login = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userInfo = await login({ email, password }).unwrap();
      console.log("info",userInfo)
      dispatch(setUserInfo({ userInfo }));
      localStorage.setItem("abUser", JSON.stringify(userInfo));
      if (userInfo?.data !== undefined) {
        navigate("/dashboard");
      } else {
        setError(userInfo?.msg);
      }
    } catch (err) {
      console.log("Failed to login", err);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-grey">
      <div className="w-[400px] h-[280px] rounded-[8px] drop-shadow bg-white flex flex-col items-center py-10 px-20">
        <p className="font-text font-bold text-[18px]">
          Sign in to your account
        </p>
        <p className="font-text text-[9px] text-deepGrey">
          Login to access your dashboard and settings
        </p>
        <input
          type="email"
          placeholder="Email address"
          className="w-[100%] font-text text-[9px] drop-shadow p-2 mt-4 outline-0"
          onChange={(e) => {
            setError("");
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          className="w-[100%] font-text text-[9px] drop-shadow p-2 mt-1 outline-0"
          onChange={(e) => {
            setError("");
            setPassword(e.target.value);
          }}
        />
        <button
          onClick={handleLogin}
          className="bg-orange text-white font-text font-bold text-[14px] bol p-1 mt-3 w-[100%] flex items-center justify-center"
        >
          {isLoading ? (
            "Checking details..."
          ) : (
            <>
              Login
              <img
                src="/assets/images/loginArrow.png"
                alt=""
                className="ml-[2px]"
              />
            </>
          )}
        </button>
        <p className="text-orange text-[10px] mt-2">{error}</p>
      </div>
    </div>
  );
};

export default Login;
