import { useEffect, useState, useRef } from "react";

import {
  useCreateAdMutation,
  useGetCategoryQuery,
  useGetSubCategoryQuery,
  useAddProductMutation,
  useAddBlogMutation,
  useGetBlogCategoryQuery
} from "../../features/api/apiSlice";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateBlog = () => {
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [subCategorySelected, setSubCategorySelected] = useState(null);
  const [title, setTitle] = useState("");
  const [postedby, setPostby] = useState("");
  const [image,setImage]=useState("");
  const [category, setCategory] = useState("")
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [duration, setDuration] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cloudUrl, setCloudUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addBlog] = useAddBlogMutation();
  const { data: blogcate, isFetching } =  useGetBlogCategoryQuery();

  console.log("this iss blog cate",blogcate)
 
  // upload image to cloudinary
  const Upload = async (e) => {
    e.preventDefault();
    //upload product image
    const data = new FormData();
    data.append("file", imageUrl);
    data.append("upload_preset", "mybuddy");
    data.append("cloud_name", "uhembe");
    setLoading(true);
    await fetch("https://api.cloudinary.com/v1_1/uhembe/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        //upload product thumbnail
        const url = data.secure_url;
        setCloudUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (cloudUrl !== null) {
      handleCreateAd();
      setLoading(false);
      setCloudUrl(null);
      setImageUrl("");
      setTitle("");
      setAmount("");
      setDuration("");
    }
  }, [cloudUrl]);

  const handleCreateAd = async () => {
    const ad = {
      title,
      postedby,
       image: cloudUrl,
      duration,
      category,
      description,
    };

    try {
      await addBlog(ad).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full bg-white rounded-[5px] h-fit mt-[20px] drop-shadow p-[20px]">
      <form
        className="flex flex-col"
        onSubmit={Upload}
        encType="multipart/form-data"
      >
        <input
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Blog title"
          className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 mb-5 text-[10px] p-[5px] text-[#717579] placeholder-[#717579]"
        />
        <input
          onChange={(e) => setPostby(e.target.value)}
          type="text"
          placeholder="Posted by"
          className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 mb-5 text-[10px] p-[5px] text-[#717579] placeholder-[#717579]"
        />

        {/* <textarea
          onChange={(e) => setPostby(e.target.value)}
          type="textarea"
          placeholder="Post"
          className="w-full border-[#D9D9D9] border-[1px] h-[100px] rounded-[2px] outline-0 mb-5 text-[10px] p-5 text-[#717579] placeholder-[#717579]"
        /> */}
        <ReactQuill theme="snow" value={description} onChange={setDescription} className="  rounded-[5px] ql-editor whitespace-normal" />


       

        <select  
        value={category} 
        onChange={e => setCategory(e.target.value)} 
        className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 mb-3 text-[10px] p-[5px] bg-white text-[#717579] mt-5 ">
         {
          blogcate?.map((i)=> {
            return  <option value={i.title}>{i.title}</option>
          })
         }
         
        </select>

       
       

       

        

        <label
          htmlFor="imageUrl"
          className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 mb-5 text-[10px] p-[5px] bg-white text-[#717579]"
        >
          {imageUrl?.name ? imageUrl?.name : "blog image"}
        </label>
        <input
          onChange={(e) => setImageUrl(e.target.files[0])}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          name="imageUrl"
          id="imageUrl"
          className="hidden"
        />
        <button
          className="w-full h-[30px] rounded-[2px] text-[10px] p-[5px] bg-orange text-white mt-[5px]"
          disabled={loading}
        >
          {loading ? "Uploading..." : "Create Blog Post"}
        </button>
      </form>
    </div>
  );
};

export default CreateBlog;
