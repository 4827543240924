import React, {useState} from 'react'
import { useGetAllordersQuery } from '../../features/api/apiSlice'


const OrderTable = () => {
    const { data: orders, isLoading, error } = useGetAllordersQuery()
    console.log("this is orders", orders)
    const [shippingd, setShippingd] = useState("")
    const [productd, setProductd] = useState('')

    const Setshipping = (i)=>{
        console.log("this is shipp",i.shippingdetails)
        setShippingd(i.shippingdetails)
    }
    const Setproduct =(i)=>{
      setProductd(i)
    }

  return (
    <div className="overflow-x-auto ">
        <div className='font-[600]'>Recent orders</div>
  <table className="table">
    {/* head */}
    <thead>
      <tr>
        <th>
          <label>
            <input type="checkbox" className="checkbox" />
          </label>
        </th>
        <th>Sender</th>
        <th>Refrence No</th>
        <th>Amount</th>
        <th>Shipping fee</th>
        <th>Payment Method</th>
        <th>Product Details</th>
        <th>Shipping Details</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}

      {
        orders?.map((i, index)=>{
            return  <tr>
        <th key={index}>
          <label>
            <input type="checkbox" className="checkbox" />
          </label>
        </th>
        <td>
          <div className="flex items-center gap-3">
            <div className="avatar">
              <div className="mask mask-squircle w-12 h-12">
                <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" alt="image" />
              </div>
            </div>
            <div>
              <div className="font-bold">{i.sendersname}</div>
              <div className="text-sm opacity-50">{i.phone}</div>
            </div>
          </div>
        </td>
        <td>
          {/* Zemlak, Daniel and Leannon
          <br/> */}
          <span className="badge badge-ghost badge-sm">{i.reference}</span>
        </td>
        <td> ₦ {i.total}</td>
        <td> ₦ {i.shippingfee}</td>
        <td> {i.paymentmethod}</td>
        <th>
          <button onClick={()=>{
          Setproduct(i)
            document.getElementById('my_modal_3').showModal()
          }

            } className="btn btn-ghost btn-xs">View details</button>
        </th>
        <th>
          <button onClick={()=> {
            
           Setshipping(i)
          document.getElementById('my_modal_4').showModal()
          }
          
            } className="btn btn-ghost btn-xs">View details</button>
        </th>
        <td>{i.status}</td>
      </tr>
        })
      }
     
    </tbody>
    {/* foot */}
   
    
  </table>


  <dialog id="my_modal_3" className="modal">
  <div className="modal-box">
    <form method="dialog">
      {/* Close button for the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>
    <h3 className="font-bold text-lg">Product Details</h3>
   
    
    {/* Displaying product images in cards, with two images per row */}
    <div className='flex flex-wrap justify-between mt-10'>
      {productd?.productDetails?.map((data, index) => (
        <div key={index} className="w-[calc(50%-20px)] mb-4">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <img src={data?.productImage} className="w-full h-auto" />
            <div className='px-3 mt-2'>Price: ₦ {data?.productPrice}</div>
            <div className='px-3'>Product: {data?.productName}</div>
            <div className='px-3'>Category: {data?.category}</div>
            <div className='px-3'>Quatity: {data?.qty}</div>
            <div className='px-3 mt-2 mb-2'>Description {data?.description && data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}</div>
            {/* Additional product info can be added here */}
          </div>
        </div>
      ))}
    </div>
    
    {/* Shipping details */}
    
    <div className='mt-8'>
      <div className='flex flex-row justify-between mt-2 mb-4'>
        <p className="font-bold">Payment Mothod: </p>
        <p>{productd.paymentmethod}</p>
      </div>
      <hr />
      <div className='flex flex-row justify-between mt-2 mb-4'>
        <p className="font-bold">Sender:</p>
        <p>{productd.sendersname}</p>
      </div>
      <hr />
      <div className='flex flex-row justify-between mt-2 mb-4'>
        <p className="font-bold">Reference:</p>
        <p>{productd?.reference}</p>
      </div>
      <hr />
      <div className='flex flex-row justify-between mt-2 mb-4'>
        <p className="font-bold">Shipping fee:</p>
        <p>{productd?.shippingfee}</p>
      </div>
      <hr />
      <div className='flex flex-row justify-between mt-2 mb-4'>
        <p className="font-bold">Total:</p>
        <p>{productd?.total}</p>
      </div>
    </div>
  </div>
</dialog>




<dialog id="my_modal_4" className="modal">
  <div className="modal-box">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>
    <h3 className="font-bold text-lg">Shipping</h3>
    <p className="py-2">Find Shipping details below</p>
    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">Phone Number: </p>
    <p className="">{shippingd?.phoneNumber}</p>
    </div>
    <hr/>

    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">Email: </p>
    <p className="">{shippingd?.email}</p>
    </div>
    <hr/>
  
    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">State: </p>
    <p className="">{shippingd?.shippingState}</p>
    </div>
    <hr/>

    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">City: </p>
    <p className="">{shippingd?.city}</p>
    </div>
    <hr/>

    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">Zipcode: </p>
    <p className="">{shippingd?.zipCode}</p>
    </div>
    <hr/>

    <div className='flex flex-row justify-between mt-4 mb-4'>
    <p className="font-bold">Address: </p>
    <p className="">{shippingd?.address}</p>
    </div>

    


  </div>
</dialog>
</div>
  )
}

export default OrderTable