import { createBrowserRouter } from "react-router-dom";
import Ads from "./Pages/Ads";
import Dashboard from "./Pages/Dashboard";
import Landing from "./Pages/Landing";
import Login from "./Pages/Login";
import Products from "./Pages/Products";
import Blog from './Pages/Blog'
import DeleteAccount from "./Pages/DeleteAccount";
import ConfirmDelete from './Pages/Confirm-delete'


const routes = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/deleteAccount",
    element: <DeleteAccount />,
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/ads",
    element: <Ads />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/delete-account/:id/:email",
    element: <ConfirmDelete />,
  },
]);

export default routes;
