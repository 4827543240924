import React, {useState} from 'react'
import {
  useGetProductsQuery,
  useDeleteProductMutation,
  useGetCategoryQuery,
  useUpdateProductMutation
} from "../../features/api/apiSlice";


function ViewProducts() {
  const { data: product, isFetching } = useGetProductsQuery();
  const [deleteProduct] = useDeleteProductMutation();
  console.log("products", product)
  const [singleProduct, setSingleProduct] = useState()
  console.log("thiss is single",singleProduct)
  const { data: ProductCategory } = useGetCategoryQuery();
  const [updateProduct] = useUpdateProductMutation();

  // const [productName, setProductName] = useState();
  // const [description, setDescription] = useState("");
  // const [productPrice, setProductPrice] = useState("");
  // const [productUrl, setProductUrl] = useState("");
  // const [cloudUrl, setCloudUrl] = useState(null);
  // const [productImageList, setProductImageList] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [isOff, setIsOff] = useState(null);
  // const [openIsOff, setOpenIsOff] = useState(false);
  // const [isAvailable, setIsAvailable] = useState(null);
  // const [openIsAvailable, setOpenIsAvailable] = useState(null);
  // const [offPercentage, setOffPercentage] = useState(null);
  // const [isAd, setIsAd] = useState("")
  // const [duration, setDuration] = useState("")
  // const [expiration, setExpiration] = useState("")
  // const [location, setLocation] = useState("")
  const [productName, setProductName] = useState(singleProduct?.productName || "");
const [description, setDescription] = useState(singleProduct?.description || "");
const [productPrice, setProductPrice] = useState(singleProduct?.productPrice || "");
const [productUrl, setProductUrl] = useState(singleProduct?.productUrl || "");
const [productImage, setProductImage] = useState(singleProduct?.productImage || null);
const [productImageList, setProductImageList] = useState(singleProduct?.productImageList || []);
const [loading, setLoading] = useState(false);
const [isOff, setIsOff] = useState(singleProduct?.isOff || null);
const [isAvailable, setIsAvailable] = useState(singleProduct?.isAvailable || null);
const [offPercentage, setOffPercentage] = useState(singleProduct?.offPercentage || null);
const [isAd, setIsAd] = useState(singleProduct?.isAd || "");
const [duration, setDuration] = useState(singleProduct?.duration || "");
const [expiration, setExpiration] = useState(singleProduct?.expiration || "");
const [location, setLocation] = useState(singleProduct?.location || "");
const [category, setCategory] = useState(singleProduct?.category || "");


  const handleUpdate = async () => {
    const payload = {
      id: singleProduct._id,
      productName: productName || singleProduct.productName,
      description: description || singleProduct.description,
      productPrice: productPrice || singleProduct.productPrice,
      productImage: productImage || singleProduct.productImage,
      productImageList: productImageList.length ? productImageList : singleProduct.productImageList,
      isOff: isOff || singleProduct.isOff,
      offPercentage: offPercentage !== null ? offPercentage : singleProduct.offPercentage,
      isAvailable: isAvailable !== null ? isAvailable : singleProduct.isAvailable,
      isAd: isAd || singleProduct.isAd,
      duration: duration || singleProduct.duration,
      expiration: expiration || singleProduct.expiration,
      location: location || singleProduct.location,
    };

    console.log("this is payload", payload)
    try {
      const response = await updateProduct(payload)
      console.log("product here", response.data.status)
     
        setProductName("");
        setDescription("");
        setProductPrice("");
        setProductImageList([]);
        setIsOff(null);
        setIsAvailable(null);
        setOffPercentage(null);
        setIsAd("");
        setDuration("");
        setExpiration("");
        setLocation("");
  
        // Close the dialog
        const dialog = document.getElementById('my_modal_3');
        dialog.close();
      


    }catch(error){
      console.log("error", error)
    }
  
    //const updateProduct = ""; // Your update logic here
  };
  const handleDeleteProduct = async (productId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      const data = await deleteProduct({ _id: productId });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };



  


  const Products = product?.map((product) => (
<>

{/* You can open the modal using document.getElementById('ID').showModal() method */}

<dialog id="my_modal_3" className="modal">
  <div className="modal-box">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>

    <h3 className="font-bold text-lg">Update product</h3>

<label className="form-control w-full max-w-full">
  <div className="label">
    <span className="label-text">Product Name</span>
  </div>
  <input
    type="text"
    value={productName}
    onChange={(e) => setProductName(e.target.value)}
    placeholder={singleProduct?.productName}
    className="input input-bordered w-full max-w-full"
  />
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Product Category</span>
  </div>
  <select
    value={category}
    onChange={(e) => setCategory(e.target.value)}
    className="select select-bordered w-full max-w-full"
  >
    <option disabled selected>{singleProduct?.category}</option>
    {ProductCategory?.map((i, index) => (
      <option key={index} value={i?.title}>{i?.title}</option>
    ))}
  </select>
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Product Description</span>
  </div>
  <input
    type="text"
    value={description}
    onChange={(e) => setDescription(e.target.value)}
    placeholder={singleProduct?.description}
    className="input input-bordered w-full max-w-full"
  />
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Product Price</span>
  </div>
  <input
    type="text"
    value={productPrice}
    onChange={(e) => setProductPrice(e.target.value)}
    placeholder={singleProduct?.productPrice}
    className="input input-bordered w-full max-w-full"
  />
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Is product out of stock?</span>
  </div>
  <select
    value={isOff}
    onChange={(e) => setIsOff(e.target.value)}
    className="select select-bordered w-full max-w-full"
  >
    <option disabled selected>{singleProduct?.isOff ? "Yes" : "No"}</option>
    <option value="false">No</option>
    <option value="true">Yes</option>
  </select>
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Is product an Ad too?</span>
  </div>
  <select
    value={isAd}
    onChange={(e) => setIsAd(e.target.value)}
    className="select select-bordered w-full max-w-full"
  >
    <option disabled selected>{singleProduct?.isAd ? "Yes" : "No"}</option>
    <option value="false">No</option>
    <option value="true">Yes</option>
  </select>
</label>

<label className="form-control w-full max-w-full mt-4">
  <div className="label">
    <span className="label-text">Location</span>
  </div>
  <select
    value={location}
    onChange={(e) => setLocation(e.target.value)}
    className="select select-bordered w-full max-w-full"
  >
    <option disabled selected>{singleProduct?.location}</option>
    <option value="all">All Screens</option>
          <option value="blog">Blog Screen</option>
          <option value="music">Music Screen</option>
          <option value="movie">Movie Screen</option>
          <option value="travel">Travel Screens</option>
          <option value="topup">Topup Screen</option>
          <option value="cable">Cable Screens</option>
          <option value="electricity">Electricity Screen</option>
          <option value="magazine">Magazine Screens</option>
          <option value="sport">Sport Screen</option>
          <option value="store">Store</option>
    {/* Add other options here */}
  </select>
</label>

<button onClick={handleUpdate} className="btn btn-wide min-w-full mt-4 bg-orange text-white">
  Update Product
</button>
  </div>
</dialog>


<tr
      key={product?._id}
      className="mb-[20px] border-t-[1px] border-t-barGrey py-[5px]"
    >
      <td className="">
        <img
          src={product?.productImage}
          alt=""
          className="h-[55px] w-[60px] m-[0 auto]"
        />
      </td>
      <td >{product?.productName}</td>
      <td >
        {"₦" + product?.productPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td >{product?.category}</td>
       <td>{
       product?.isOff ? "Out of Stock" : "In Stock"}</td>
      <td>
        {/* <button className="bg-orange text-white font-subtext mr-[10px] py-[2px] px-[10px] font-[500] rounded-sm">
          Edit
        </button> */}
        <button
          className="bg-orange text-white font-subtext py-[2px] px-[10px] font-[500] rounded-sm"
          onClick={() => handleDeleteProduct(product?._id)}
        >
          Delete
        </button>
        <button
          className="bg-orange text-white font-subtext py-[2px] px-[10px] font-[500] rounded-sm ml-10"
          onClick={()=>{document.getElementById('my_modal_3').showModal() 
          setSingleProduct(product)
        }}
          
          // onClick={() => handleDeleteProduct(product)}
        >
          Update Product
        </button>
      </td>
    </tr>
</>
    
    

  ));

  return (
    <div className="w-full h-[80vh] bg-white rounded-[5px]  mt-[20px] drop-shadow p-[20px] overflow-y-auto">
      <div className="flex items-center rounded-[20px] border-deepGrey border-[1px] pl-[15px]">
        <img
          src="/assets/images/search.png"
          alt=""
          className="h-[20px] w-[20px]"
        />
        <input
          type="text"
          placeholder="Search brand, model or category "
          className="w-[90%] border-0 h-[30px] rounded-[2px] outline-0  text-[12px] p-[5px] text-[#717579] "
        />
      </div>
      {/* <table className=" w-full mt-10 overflow-y-auto">
        <thead className="font-text text-[14px] text-center">
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Quantity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="font-text text-[14px] text-left">
          {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            Products
          )}
        </tbody>
      </table> */}


<table className="table">
    {/* head */}
    <thead>
      <tr>
       
        <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Stock</th>
            <th>Actions</th>
      </tr>
    </thead>
    <tbody>
    {isFetching ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            Products
          )}
     
    </tbody>
    {/* foot */}
   
    
  </table>


    </div>
  );
}

export default ViewProducts;
