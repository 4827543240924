import React, {useState} from "react";
import { useSendDeleteLinkMutation } from "../features/api/apiSlice";

const DeleteAccount = () => {
    const [sendLink, ] = useSendDeleteLinkMutation()
    const [email, setEmail] = useState('')

   const Dodelete= async()=>{
    if(!email){
        alert("email can not be emty")
    }

    const payload = {
        email:email
    }
    try{
      const response =  await sendLink(payload)
      console.log("this is it ", response)
      if(response?.data.status){
        alert(response.data.message)
        setEmail('')
      }else{
        alert(response.data.message)
      }
    //   if(send){
    //     console.log("this is data", data)
    //     return   alert("A delete Link has been sent to your email")
    //   }
    
    }catch(error){
     alert(error)
    }

    

   }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-200 p-4 rounded-lg">
        <h1 className="text-2xl font-bold mb-4">Delete Account</h1>
        <p className="mb-4">A Delete Link will be sent to your Email.</p>
        <label className="input input-bordered flex items-center gap-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
  <input
    onChange={(e) => {
        setEmail(e.target.value);
      }}
   type="text" className="grow" placeholder="Email" />
</label>

<button
onClick={Dodelete}
 className="btn w-full mt-5 bg-orange text-white">Delete your account</button>
      </div>
    </div>
  );
};

export default DeleteAccount;