import { useState } from "react";
import { navigation } from "../Utils/MenuData";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const NavMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const circleVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 180,
      transition: {
        type: "spring",
        stiffness: 160,
        damping: 60,
      },
    },
  };

  const ulVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
  };

  return (
    <nav className="relative">
      <div
        onClick={() => setIsOpen(true)}
        className="cursor-point text-primary"
      >
        <img
          src="/assets/images/menu.png"
          alt=""
          className="absolute top-[20px] right-[15px] cursor-pointer"
        />
      </div>

      <motion.div
        variants={circleVariants}
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        className="w-4 h-4 rounded-full bg-black fixed top-0 right-0"
      ></motion.div>

      <motion.ul
        variants={ulVariants}
        initial="hidden"
        animate={isOpen ? "visible" : ""}
        className={`${
          isOpen ? "right-0" : "right-full"
        } fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all
           duration-300 overflow-hidden 
           `}
      >
        <div
          onClick={() => setIsOpen(false)}
          className="cursor-pointer absolute top-8 right-8 text-white"
        >
          <img src="/assets/images/cancel.png" alt="" />
        </div>

        {navigation.map((item, index) => {
          return (
            <li key={index} className="mb-8">
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to={item.href}
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
                className="transition-all
              duration-300 text-white"
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </motion.ul>
    </nav>
  );
};

export default NavMobile;
