import { useState } from "react";
import {
  useAddCategoryMutation,
  useGetCategoryQuery,
  useDeleteCategoryMutation,
} from "../../features/api/apiSlice";

const Category = () => {
  const [category, setCategory] = useState("");
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const { data: ProductCategory } = useGetCategoryQuery();
  const [deleteCategory, { isLoading: isDeleting }] =useDeleteCategoryMutation();

  console.log("all good herre",ProductCategory)


  //Create Category Function
  const handleAddCategory = async (e) => {
    e.preventDefault();
    const newCategory = { title: category };
    try {
      const data = await addCategory(newCategory);
      console.log(data);
      e.target.reset();
    } catch (error) {
      console.log(error);
    }
  };

  //Delete Category Function
  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      const data = await deleteCategory({ _id: categoryId });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* Create Category */}

      <div className="w-full bg-white rounded-[5px] h-fit mt-[20px] drop-shadow p-[20px]">
        <form className="flex flex-col" onSubmit={handleAddCategory}>
          <input
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            type="text"
            placeholder="New category"
            className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 mb-5 text-[10px] p-[5px] text-[#717579] placeholder-[#717579]"
          />
          <button className="w-full h-[30px] rounded-[2px] text-[10px] p-[5px] bg-orange text-white mt-[5px]">
            {isLoading ? "Creating category..." : "Add Category"}
          </button>
        </form>
      </div>

      {/* Delete Category */}

      <div className="w-full bg-white rounded-[5px] h-fit mt-[20px] drop-shadow p-[20px]">
        <form className="flex flex-col" onSubmit={handleDeleteCategory}>
          <div className="mb-[20px] relative">
            <div
              className="w-full border-[#D9D9D9] border-[1px] h-[30px] rounded-[2px] outline-0 text-[10px] p-[5px] bg-white text-[#717579] flex justify-between items-center "
              onClick={() => setOpenCategory(!openCategory)}
            >
              <span>
                {categorySelected !== null
                  ? categorySelected
                  : "Select a category to be deleted"}
              </span>{" "}
              <img
                src="/assets/images/arrowDown.png"
                alt=""
                className={`h-[5px] w-[8px] ${openCategory && "rotate-180"}`}
              />
            </div>
            <ul
              className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
                !openCategory && "hidden "
              }`}
            >
              {ProductCategory?.map((category) => (
                <li
                  key={category?._id}
                  className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                    categorySelected === category?.title
                      ? "bg-orange text-white"
                      : "bg-white text-[#717579]"
                  }`}
                  onClick={() => {
                    setCategorySelected(category?.title);
                    setCategoryId(category?._id);
                    setOpenCategory(false);
                  }}
                >
                  {category?.title}
                </li>
              ))}
            </ul>
          </div>
          <button className="w-full h-[30px] rounded-[2px] text-[10px] p-[5px] bg-orange text-white mt-[5px]">
            {isDeleting ? "Deleting category..." : "Delete category"}
          </button>
        </form>
      </div>
    </>
  );
};

export default Category;
