// navigation
export const navigation = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "About Us",
    href: "/",
  },
  {
    name: "Privacy Policy",
    href: "/",
  },
  {
    name: "Our Blog",
    href: "/",
  },
  {
    name: "Login",
    href: "/login",
  },
];
