import { useEffect, useState } from "react";
import {
  useAddProductMutation,
  useGetCategoryQuery,
} from "../../features/api/apiSlice";

import { useDispatch } from "react-redux";

import { setPage } from "../../features/product/productSlice";

const AddProducts = () => {
  const dispatch = useDispatch();
  const [openCategory, setOpenCategory] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [cloudUrl, setCloudUrl] = useState(null);
  const [productImageList, setProductImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOff, setIsOff] = useState(null);
  const [openIsOff, setOpenIsOff] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [openIsAvailable, setOpenIsAvailable] = useState(null);
  const [offPercentage, setOffPercentage] = useState(null);
  const [isAd, setIsAd] = useState("")
  const [duration, setDuration] = useState("")
  const [expiration, setExpiration] = useState("")
  const [location, setLocation] = useState("")

  const [addProduct] = useAddProductMutation();
  const { data: ProductCategory } = useGetCategoryQuery();
  console.log("here",ProductCategory)
  let productCloudImageList = [];
  let posturl

  const handleImageChange = (e) => {
    const imageList = e.target.files;
    let limit = imageList?.length < 3 ? imageList?.length : 3;
    let imageArr = [];
    if (imageList.length > 0) {
      for (let i = 0; i < limit; i++) {
        imageArr.push(imageList[i]);
      }
      setProductImageList(imageArr);
    }
  };
  // upload image to cloudinary
  const Upload = async (e) => {
    e.preventDefault();
    //upload product image
    const data = new FormData();
    data.append("file", productUrl);
    data.append("upload_preset", "mybuddy");
    data.append("cloud_name", "uhembe");
    setLoading(true);
    await fetch("https://api.cloudinary.com/v1_1/uhembe/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then(async (data) => {
        const url = await data.secure_url;
        posturl = url
         setCloudUrl(url);
        //upload product image list
        for (let i = 0; i < productImageList?.length; i++) {
          const listData = new FormData();
          listData.append("file", productImageList[i]);
          listData.append("upload_preset", "mybuddy");
          listData.append("cloud_name", "uhembe");
          fetch("https://api.cloudinary.com/v1_1/uhembe/image/upload", {
            method: "post",
            body: listData,
          })
            .then((res) => res.json())
            .then((data) => {
              const url = data.secure_url;
              listData.delete("file");
              listData.delete("upload_preset");
              listData.delete("cloud_name");
              productCloudImageList.push(url);
              console.log(productCloudImageList.length)
              console.log("list",productImageList.length)
              if(productCloudImageList.length === productImageList.length ){
                handleAddProduct()
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      cloudUrl !== null &&
      productCloudImageList?.length <= productImageList?.length + 1
    ) {
      // handleAddProduct();
      // setLoading(false);
      // setCloudUrl(null);
      // setProductUrl("");
      // setProductName("");
      // setDescription("");
      // setProductPrice("");
      // setIsAd(false)
    }
  }, [productImageList?.length, cloudUrl, productCloudImageList?.length]);

  const handleAddProduct = async () => {
    const product = {
      category: categorySelected,
      productName,
      productPrice,
      description,
      isOff,
      offPercentage,
      productImage: posturl,
      isAvailable,
      productImageList: productCloudImageList,
      isAd,
      duration,
      expiration,
      location
    };


    console.log("this is product",product)
    try {
      await addProduct(product).unwrap();
      dispatch(setPage(1));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className=" bg-white rounded-[5px]  mt-[20px] drop-shadow p-[20px]">
      <form className="flex flex-col" onSubmit={Upload}>
        <input
          onChange={(e) => setProductName(e.target.value)}
          type="text"
          placeholder="Product Name"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[5px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        />
        <textarea
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Product description"
          className="w-full border-[#D9D9D9] border-[1px] h-[50px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        ></textarea>
        <input
          onChange={(e) => setProductPrice(e.target.value)}
          type="text"
          placeholder="Product price"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[5px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        />
        {/* isOff */}
        <div className="mb-[20px] relative">
          <div
            className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 text-[14px] p-[8px] bg-white text-[#717579] flex justify-between items-center "
            onClick={() => setOpenIsOff(!openIsOff)}
          >
            <span>
              {isOff === null
                ? "Is product off?"
                : isOff === true
                ? "Yes"
                : "No"}
            </span>{" "}
            <img
              src="/assets/images/arrowDown.png"
              alt=""
              className={`h-[5px] w-[8px] ${openIsOff && "rotate-180"}`}
            />
          </div>
          <ul
            className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
              !openIsOff && "hidden "
            }`}
          >
            <li
              className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                isOff === true
                  ? "bg-orange text-white"
                  : "bg-white text-[#717579]"
              }`}
              onClick={() => {
                setIsOff(true);
                setOpenIsOff(false);
              }}
            >
              Yes
            </li>

            <li
              className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                isOff === false
                  ? "bg-orange text-white"
                  : "bg-white text-[#717579]"
              }`}
              onClick={() => {
                setIsOff(false);
                setOpenIsOff(false);
              }}
            >
              No
            </li>
          </ul>
        </div>
        {/* Off percentage */}
        <input
          onChange={(e) => setOffPercentage(e.target.value)}
          type="text"
          placeholder="Off percentage"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[#717579] text-[14px] p-[8px] placeholder-[#717579]"
        />

        {/* isAvailable */}
        <div className="mb-[20px] relative">
          <div
            className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 text-[14px] p-[8px] bg-white text-[#717579] flex justify-between items-center "
            onClick={() => setOpenIsAvailable(!openIsAvailable)}
          >
            <span>
              {isAvailable === null
                ? "Is product available?"
                : isAvailable === true
                ? "Yes"
                : "No"}
            </span>{" "}
            <img
              src="/assets/images/arrowDown.png"
              alt=""
              className={`h-[5px] w-[8px] ${openIsAvailable && "rotate-180"}`}
            />
          </div>
          <ul
            className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
              !openIsAvailable && "hidden "
            }`}
          >
            <li
              className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                isAvailable === true
                  ? "bg-orange text-white"
                  : "bg-white text-[#717579]"
              }`}
              onClick={() => {
                setIsAvailable(true);
                setOpenIsAvailable(false);
              }}
            >
              Yes
            </li>

            <li
              className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                isAvailable === false
                  ? "bg-orange text-white"
                  : "bg-white text-[#717579]"
              }`}
              onClick={() => {
                setIsAvailable(false);
                setOpenIsAvailable(false);
              }}
            >
              No
            </li>
          </ul>
        </div>

        <div className="mb-[20px] relative">
          <div
            className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 text-[14px] p-[8px] bg-white text-[#717579] flex justify-between items-center "
            onClick={() => setOpenCategory(!openCategory)}
          >
            <span>
              {categorySelected !== null
                ? categorySelected
                : "Product category"}
            </span>{" "}
            <img
              src="/assets/images/arrowDown.png"
              alt=""
              className={`h-[5px] w-[8px] ${openCategory && "rotate-180"}`}
            />
          </div>
          <ul
            className={`my-[5px] border-[1px] border-[#D9D9D9] rounded-[2px] drop-shadow  absolute w-full z-[1] ${
              !openCategory && "hidden "
            }`}
          >
            {ProductCategory?.map((category) => (
              <li
                key={category?._id}
                className={`w-full border-y-[1px] border-y-white p-[5px]  hover:bg-orange hover:text-white text-[10px]  ${
                  categorySelected === category?.title
                    ? "bg-orange text-white"
                    : "bg-white text-[#717579]"
                }`}
                onClick={() => {
                  setCategorySelected(category?.title);
                  setOpenCategory(false);
                }}
              >
                {category?.title}
              </li>
            ))}
          </ul>
        </div>

        <label
          htmlFor="productUrl"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] bg-white text-[#717579]"
        >
          {productUrl?.name ? productUrl?.name : "Product image"}
        </label>
        <input
          onChange={(e) => setProductUrl(e.target.files[0])}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          name="productUrl"
          id="productUrl"
          className="hidden"
        />

        <label
          htmlFor="productImageList"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] bg-white text-[#717579]"
        >
          {productImageList?.length > 0
            ? productImageList?.map((image, index) => (
                <span key={index} className="mr-[5px]">
                  {image?.name}
                </span>
              ))
            : "Product image list"}
        </label>
        <input
          onChange={(e) => {
            handleImageChange(e);
          }}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          name="productImageList"
          id="productImageList"
          multiple
          className="hidden"
        />
          <label  className="w-full border-[#D9D9D9] text-[10px] p-[5px] bg-white text-[#717579]">Is this product an Ad</label>
        <select  
        value={isAd} 
        onChange={e => setIsAd(e.target.value)} 
        className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-3 text-[14px] p-[8px] bg-white text-[#717579]">
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>

        {
          isAd === "yes"? 
          <div>
             <input
          onChange={(e) => setDuration(e.target.value)}
          type="number"
          placeholder="Ad Duration, e.g 30days"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        />

         <input
          onChange={(e) => setExpiration(e.target.value)}
          type="date"
          placeholder="expiration date"
          className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-5 text-[14px] p-[8px] text-[#717579] placeholder-[#717579]"
        />

        <label  className="w-full border-[#D9D9D9] text-[14px] p-[5px] bg-white text-[#717579]">Select where you want this ad to display on mobile app</label>
        <select  
        value={location} 
        onChange={e => setLocation(e.target.value)} 
        className="w-full border-[#D9D9D9] border-[1px] h-[40px] rounded-[2px] outline-0 mb-3 text-[14px] p-[8px] bg-white text-[#717579]">
          <option value="all">All Screens</option>
          <option value="blog">Blog Screen</option>
          <option value="music">Music Screen</option>
          <option value="movie">Movie Screen</option>
          <option value="travel">Travel Screens</option>
          <option value="topup">Topup Screen</option>
          <option value="cable">Cable Screens</option>
          <option value="electricity">Electricity Screen</option>
          <option value="magazine">Magazine Screens</option>
          <option value="sport">Sport Screen</option>
          <option value="store">Store</option>
        </select>

          </div>
           : null
        }




        <button
          className="w-full h-[40px] rounded-[2px] text-[14px] p-[5px] bg-orange text-white mt-[5px]"
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload product"}
        </button>
      </form>
    </div>
  );
};

export default AddProducts;
