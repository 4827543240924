import { Link } from "react-router-dom";
import NavMobile from "./NavMenu";

const MainNavigation = () => {
  return (
    <nav className="w-full h-20 px-4 py-4 flex justify-between items-center container mx-auto relative">
      <div className="md:hidden absolute right-0 left-0 top-0 bottom-0">
        <NavMobile />
      </div>
      <div className="flex  items-center ">
        <Link to={"/"} className="font-text text-2xl font-semibold text-orange">
          ADBoard
        </Link>
        <Link to={"/"} className="ml-10 text-white text-sm  hidden md:block">
          Home
        </Link>
        <Link to={"/"} className="ml-10 text-white text-sm hidden md:block">
          About Us
        </Link>
      </div>
      <div className="flex ">
        <Link
          to={"/privacy"}
          className="ml-10 mr-10 text-white text-sm hidden md:block"
        >
          Privacy policy
        </Link>

        <Link to={"/"} className="text-white text-sm hidden md:block">
          Our Blog
        </Link>
        <Link
          to={"/login"}
          className="ml-10 text-white text-sm hidden md:block"
        >
          Login
        </Link>
        <Link
          to={"/DeleteAccount"}
          className="ml-10 text-white text-sm hidden md:block"
        >
          Delete Account
        </Link>
      </div>
    </nav>
  );
};

export default MainNavigation;
