import { useEffect } from "react";
import Layout from "../Components/Layout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfo } from "../features/user/userSlice";
import OrderTable from "../Components/Order/OrderTable";

const Chart = (
  <>
  {/* <div className="flex w-[100%] flex-col md:flex-row items-center px-10 gap-10">
    <div className="mt-10  grid grid-cols-1 md:grid-cols-2 gap-5 w-[100%] md:w-[50%]">
      <div className="h-[8rem] w-[100%] bg-white rounded-[10px] drop-shadow pl-[20px] pt-[10px]">
        <p className="font-text font-bold text-[16px] text-textBlack">
          N 480,982.74
        </p>
        <p className="font-text font-semibold text-[10px] text-deepGrey">
          Lifetime total <br /> revenue
        </p>
        <img
          src="/assets/images/cardSecurity.png"
          alt=""
          className="float-right h-[65px] w-[80px] mt-[-5px] mr-[0.5px]"
        />
      </div>
      <div className="h-[8rem] w-[100%] bg-white rounded-[10px] drop-shadow pl-[20px] pt-[10px]">
        <p className="font-text font-bold text-[16px] text-textBlack">37,274</p>
        <p className="font-text font-semibold text-[10px] text-deepGrey">
          Total number of <br /> orders
        </p>
        <img
          src="/assets/images/cardBox.png"
          alt=""
          className="float-right h-[65px] w-[80px] mt-[-5px] mr-[0.5px]"
        />
      </div>
      <div className="h-[8rem] w-[100%] bg-white rounded-[10px] drop-shadow pl-[20px] pt-[10px]">
        <p className="font-text font-bold text-[16px] text-textBlack">2000</p>
        <p className="font-text font-semibold text-[10px] text-deepGrey">
          Total number of active <br /> others
        </p>
        <img
          src="/assets/images/cardBar.png"
          alt=""
          className="float-right h-[65px] w-[80px] mt-[-5px] mr-[0.5px]"
        />
      </div>
      <div className="h-[8rem] w-[100%] bg-white rounded-[10px] drop-shadow pl-[20px] pt-[10px]">
        <p className="font-text font-bold text-[16px] text-textBlack">2000</p>
        <p className="font-text font-semibold text-[10px] text-deepGrey">
          Total number of <br /> shopping fulfilment
        </p>
        <img
          src="/assets/images/cardBar.png"
          alt=""
          className="float-right h-[65px] w-[80px] mt-[-5px] mr-[0.5px]"
        />
      </div>
    </div>
    <div className="w-[100%] md:w-[50%]">
      <div className="h-[17rem] w-full bg-white rounded-[10px] drop-shadow mt-10 p-2">
        <div className="flex justify-between p-5">
          <div>
            <p className="font-text text-textBlack text-[10px] font-bold">
              Total revenue
            </p>
            <p className="font-text text-[16px] font-bold text-textBlack">
              N 27,446.77
            </p>
          </div>
          <div className="flex text-[8.5px] w-[9rem] justify-between">
            <p className="flex">
              <img
                src="/assets/images/accept2.png "
                alt=""
                className="h-[10px] w-[10px] mr-[2px] font-semibold text-textGrey"
              />
              Life time
            </p>
            <p className="flex">
              <img
                src="/assets/images/accept2.png"
                alt=""
                className="h-[10px] w-[10px] mr-[2px] font-semibold text-textGrey"
              />
              Monthly
            </p>
            <p className="flex">
              <img
                src="/assets/images/accept1.png"
                alt=""
                className="h-[10px] w-[10px] mr-[2px] font-semibold text-textGrey"
              />
              Weekly
            </p>
          </div>
        </div>
        <img
          src="/assets/images/graph.svg"
          alt=""
          className="h-[10rem] w-full "
        />
      </div>
    </div>
  </div> */}

  <div className="px-10 mt-10 mb-10">
    <OrderTable/>
  </div>
</>


);

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector(userInfo);
  useEffect(() => {
    if (user === null) {
      navigate("/");
    }
  }, [navigate, user]);
  return <Layout child={Chart} />;
};

export default Dashboard;
