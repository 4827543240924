import React from "react";
import { useDeleteAccountMutation } from "../features/api/apiSlice";
import { useParams } from "react-router-dom";

const ConfirmDelete = () => {
    const [deleteA, {isLoading}]= useDeleteAccountMutation()
    const { id,email } = useParams();
    console.log("here", id)

    const handleDelete = async ()=>{
        const payload ={
            email:email,
            id:id
        }
        try{
          const response = await deleteA(payload);
        console.log(response.data)
        if(response.data.status){
            alert(response.data.message)
        }else{
            alert(response.data.message)
        }
        }catch(error){
            alert(error)
        }


      

    }



  

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-200 p-4 rounded-lg">
        <h1 className="text-[16px] font-['400] mb-4">Are you sure you want to delete your account</h1>
        {
            isLoading && 
            <div>
                <p className="mb-4 justify-center items-center flex">
            <span className="loading loading-spinner loading-lg"></span>
        </p>
        <p className="mb-4 justify-center items-center flex">
        <div className="flex justify-center, items-center">deleting account</div>
        </p>
           </div>
        }
        
       

<button

onClick={handleDelete}

 className="btn w-full mt-5 bg-orange text-white">Delete your account</button>
      </div>
    </div>
  );
};

export default ConfirmDelete;