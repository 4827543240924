import Layout from "../Components/Layout";
import AddProducts from "../Components/Products/AddProducts";
import AddCategory from "../Components/Products/Category";
import ViewProducts from "../Components/Products/ViewProducts";
import { useSelector, useDispatch } from "react-redux";
import { productPage, setPage } from "../features/product/productSlice";
const Products = () => {
  const page = useSelector(productPage);
  const dispatch = useDispatch();

  return (
    <Layout
      child={
        <div className="flex items-center justify-center w-[100%] mt-10 mb-10">
          <div className="md:w-[60%] w-[60%]">
            <div className="w-full h-[3rem] bg-orange px-[15px] flex items-center text-[1rem] ">
              <div
                className="h-[10px] pr-[15px] border-r-2 text-white flex items-center cursor-pointer"
                onClick={() => dispatch(setPage(0))}
              >
                Add Products
              </div>
              <div
                className="h-[10px] px-[15px] border-r-2 text-white flex items-center cursor-pointer"
                onClick={() => dispatch(setPage(1))}
              >
                View Products
              </div>
              <div
                className="h-[20px] px-[15px] text-white flex items-center cursor-pointer"
                onClick={() => dispatch(setPage(2))}
              >
                Category
              </div>
            </div>
            {page === 0 ? (
              <AddProducts />
            ) : page === 1 ? (
              <ViewProducts />
            ) : (
              <AddCategory />
            )}
          </div>
        </div>
      }
    />
  );
};

export default Products;
