import Moment from "react-moment";
import { useGetAdsQuery,  useDeleteProductMutation, } from "../../features/api/apiSlice";
import { useEffect, useState } from 'react';

function ViewAds() {
  const { data: ads, isFetching } = useGetAdsQuery();
  const [deleteProduct] = useDeleteProductMutation();
  const[dee, setDee] = useState(false)

  useEffect(()=>{
    
  }, [dee])

  const handleDeleteProduct = async (productId) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    try {
      const data = await deleteProduct({ _id: productId });
      console.log("delete",data);
      if(data?.data?.msg == "Product deleted"){
        window.alert("Deleted Successfully");
        window.location.reload(false);
      }else{
        alert("there was an  error deleting the product");
      }
      ads()
    } catch (error) {
      console.log(error);
    }
  };
  console.log("this is adds ",ads)

  const Ads = ads?.data?.map((ads) => (
    <tr
      key={ads?._id}
      className="mb-[20px] border-t-[1px] border-t-barGrey py-[5px]"
    >
      <td className="max-w-[5rem]">{ads?.productName}</td>
      <td className="w-[2rem]">
        <img
          src={ads?.productImage}
          alt=""
          className="h-[30px] w-[30px] m-[0 auto]"
        />
      </td>
      <td className="max-w-[5rem]">{ads?.category}</td>
      <td className="max-w-[5rem]">
        {"₦" + ads?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </td>
      <td className="max-w-[5rem]">
        <Moment format="MMMM DD, YYYY">{ads?.createdDate}</Moment>
      </td>
      <td className="max-w-[5rem]">{ads?.duration}</td>
      <td className="max-w-[5rem]">
        <Moment format="MMMM DD, YYYY">{ads?.expiryDate}</Moment>
      </td>
      <td>
      <button
          className="bg-orange text-white font-subtext py-[2px] px-[10px] font-[500] rounded-sm"
          onClick={() => handleDeleteProduct(ads?._id)}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="w-full h-[80vh] bg-white rounded-[5px]  mt-[20px] drop-shadow p-[20px] overflow-y-auto">
      <div className="flex items-center rounded-[20px] border-deepGrey border-[1px] pl-[15px]">
        <img
          src="/assets/images/search.png"
          alt=""
          className="h-[20px] w-[20px]"
        />
        <input
          type="text"
          placeholder="Search brand, model or category "
          className="w-[90%] border-0 h-[30px] rounded-[2px] outline-0  text-[12px] p-[5px] text-[#717579] "
        />
      </div>
      <table className=" w-full mt-10 overflow-y-auto">
        <thead className="font-text text-[12px] text-left">
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Category</th>
            <th>Amount</th>
            <th>Created Date</th>
            <th>Duration</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody className="font-text text-[12px] text-left">
          {isFetching ? "Loading..." : Ads}
        </tbody>
      </table>
    </div>
  );
}

export default ViewAds;
