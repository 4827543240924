// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define our single API slice object
export const apiSlice = createApi({
  reducerPath: "api",
  //http://localhost:5000
  //https://ill-blue-scarab-garb.cyclic.app
  //https://mybudy-c81a341193af.herokuapp.com/
  baseQuery: fetchBaseQuery({ baseUrl: "https://mybudy.lm.r.appspot.com/" }),
  tagTypes: ["Product", "Category", "SubCategory", "Ads", "Blog"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: "/users/login",
        method: "POST",
        body: user,
      }),
    }),
    getProducts: builder.query({
      query: () => ({
        url: "/users/getAllProductsFrontend",
      }),
      providesTags: ["Product"],
      invalidatesTags: ["Order"]
    }),


    addProduct: builder.mutation({
      query: (product) => ({
        url: "/users/addProduct",
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["Product"],
    }),

    addBlog: builder.mutation({
      query: (payload) => ({
        url: "/blog/createblog",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Blog"],
    }),

    getBlog: builder.query({
      query: () => ({
        url: "/blog/getAllblog",
      }),
      providesTags: ["Blog"],
    }),

    addBlogCategory: builder.mutation({
      query: (payload) => ({
        url: "/blog/createblogCategory",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Blog"],
    }),

    getBlogCategory: builder.query({
      query: () => ({
        url: "/blog/getAllblogCategory",
      }),
      providesTags: ["Blog"],
    }),

    deleteBlog: builder.mutation({
      query: (_id) => ({
        url: "/blog/deleteBlog",
        method: "POST",
        body: _id,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          console.log(args);
          dispatch(
            apiSlice.util.updateQueryData("getBlog", undefined, (draft) => {
              console.log(JSON.stringify(draft));
              //delete
              return draft?.filter((blog) => blog?._id !== args?._id);
            })
          );

          
        } catch (error) {
          console.log(error);
        }
      },
    }),

    deleteBlogCat: builder.mutation({
      query: (_id) => ({
        url: "/blog/deleteBlogCat",
        method: "POST",
        body: _id,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          console.log(args);
          dispatch(
            apiSlice.util.updateQueryData("getBlogCategory", undefined, (draft) => {
              console.log(JSON.stringify(draft));
              //delete
              return draft?.filter((getBlogCategory) => getBlogCategory?._id !== args?._id);
            })
          );

        } catch (error) {
          console.log(error);
        }
      },
    }),


    addCategory: builder.mutation({
      query: (category) => ({
        url: "/users/createCategory",
        method: "POST",
        body: category,
      }),
      invalidatesTags: ["Category"],
    }),


    addSubCategory: builder.mutation({
      query: (category) => ({
        url: "/users/createSubCategory",
        method: "POST",
        body: category,
      }),
      invalidatesTags: ["SubCategory"],
    }),


    getCategory: builder.query({
      query: () => ({
        url: "/users/getAllCategory",
      }),
      providesTags: ["Category"],
    }),


    getSubCategory: builder.query({
      query: () => ({
        url: "/users/getAllSubCategory",
      }),
      providesTags: ["SubCategory"],
    }),


    getAds: builder.query({
      query: () => ({
        url: "/users/getallads",
      }),
      providesTags: ["Ads"],
    }),

    createAd: builder.mutation({
      query: (ad) => ({
        url: "/users/createad",
        method: "POST",
        body: ad,
      }),
      invalidatesTags: ["Ads"],
    }),


    deleteProduct: builder.mutation({
      query: (_id) => ({
        url: "/users/deleteProduct",
        method: "POST",
        body: _id,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          console.log(args);
          dispatch(
            apiSlice.util.updateQueryData("getProducts", undefined, (draft) => {
              console.log(JSON.stringify(draft));
              //delete
              return draft?.filter((product) => product?._id !== args?._id);
            })
          );

          
        } catch (error) {
          console.log(error);
        }
      },
    }),


   




    updateProduct: builder.mutation({
      query: (payload) => ({
        url: "/users/updateproduct",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          console.log(args);
          dispatch(
            apiSlice.util.updateQueryData("getProducts", undefined, (draft) => {
              console.log(JSON.stringify(draft));
              //delete
              return draft?.filter((product) => product?._id !== args?._id);
            })
          );

          
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ["Product"]
    }),


    deleteCategory: builder.mutation({
      query: (_id) => ({
        url: "/users/deleteCategory",
        method: "POST",
        body: _id,
      }),
      invalidatesTags: ["Category"],
    }),
    deleteSubCategory: builder.mutation({
      query: (_id) => ({
        url: "/users/deleteSubCategory",
        method: "POST",
        body: _id,
      }),
      invalidatesTags: ["SubCategory"],
    }),

    getAllorders: builder.query({
      query: () => ({
        url: "/users/getAllOrders",
      }),
      providesTags: ["Order"],
      invalidatesTags: ["Orders"]
      
    }),
    sendDeleteLink: builder.mutation({
      query: (payload) => ({
        url: "/senddelete-link",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Blog"],
    }),

    DeleteAccount: builder.mutation({
      query: (payload) => ({
        url: "/deleteAccount",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Blog"],
    }),




  }),
});

export const {
  useLoginMutation,
  useAddProductMutation,
  useGetProductsQuery,
  useGetCategoryQuery,
  useGetSubCategoryQuery,
  useAddCategoryMutation,
  useAddSubCategoryMutation,
  useGetAdsQuery,
  useCreateAdMutation,
  useDeleteProductMutation,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation,
  useAddBlogMutation,
  useGetBlogQuery,
  useAddBlogCategoryMutation,
  useGetBlogCategoryQuery,
  useGetAllordersQuery, 
  useSendDeleteLinkMutation, 
  useDeleteAccountMutation,
  useUpdateProductMutation,
  useDeleteBlogMutation,
  useDeleteBlogCatMutation
} = apiSlice;
